import { Injectable } from '@angular/core';

import { HEALTH_HISTORY_ADULT_SURVEY_SYSTEM_NAME } from '@app/core/global-constants';
import { SurveySystemNames } from '@app/survey/survey-models';
import { appendQueryParamsToPath } from '@app/utils/link.utils';

@Injectable({
  providedIn: 'root',
})
export class LinksService {
  // Emails
  readonly adminEmail: string = 'mailto:admin@onemedical.com';
  readonly maEmail: string = 'mailto:ma@onemedical.com';
  readonly techsupportEmail: string = 'mailto:techsupport@onemedical.com';

  // Patient UI Paths
  readonly home: string = '/';
  readonly homeDashboard = '/pt';
  readonly login: string = '/login';
  readonly forgotPassword: string = '/forgot_password';
  readonly membershipRenewConsumer: string = '/membership/renew/consumer';
  readonly membershipRenewEnterprise: string = '/membership/renew/enterprise';
  readonly membershipRenewOMNow: string = '/membership/renew/omnow';
  readonly membershipUpgrade: string = '/membership/upgrade';
  readonly prepaidConfirmation: string = '/membership/prepaid/confirmation';
  readonly prepaidInvite: string = '/membership/prepaid/invite';
  readonly prepaidInviteChild: string = '/membership/prepaid/invite/child';
  readonly membershipSettings: string = '/membership/settings';
  readonly amazonPayManageMemberships: string = '/membership/manage';
  readonly verifyEmail: string = '/membership/verify-email';
  readonly registrationEnterprise: string = '/registration/enterprise';
  readonly registrationLimitedAccess = '/registration/limited_access';
  readonly registrationExpedited: string = '/register/expedited';
  readonly registrationConsumer: string = `/register`;
  readonly registrationRoot: string = '/registration';
  readonly registrationFamily: string = `${this.registrationRoot}/family`;
  readonly registrationPediatric: string = `${this.registrationRoot}/pediatric`;
  readonly registrationPlans: string = `${this.registrationRoot}/plans`;
  readonly registrationPaid: string = `${this.registrationRoot}/paid`;
  readonly registrationError: string = `${this.registrationRoot}/error`;
  readonly registrationSignup: string = `${this.registrationRoot}/signup`;
  readonly registrationPaidLoginWithAmazon: string = `${this.registrationPaid}/login_with_amazon`;
  readonly registrationServiceArea: string = `${this.registrationRoot}/service-area`;
  readonly renewalOptions: string = '/membership/renewal-options';
  readonly prepaidEnrollmentConversion: string = '/registration/paid/conversion';
  readonly newAppointment: string = '/appointments/new';
  readonly requestRecords: string = '/account/request-records';
  readonly notificationSettings: string = '/account/notification-settings';
  readonly healthInformationExchange: string = '/account/health-information-exchange';
  readonly profile: string = '/account/profile';
  readonly connectedAccounts: string = '/account/connected-accounts';
  readonly security: string = '/account/security';
  readonly family: string = '/account/family';
  readonly fsaHsaReimbursement: string = this.survey(SurveySystemNames.FSA_HSA_REIMBURSEMENT);
  readonly healthSurvey: string = this.survey(HEALTH_HISTORY_ADULT_SURVEY_SYSTEM_NAME);
  readonly medicalRecordsRequest: string = this.survey(SurveySystemNames.MEDICAL_RECORDS_REQUEST);
  readonly obtainMedicalRecords: string = this.survey(SurveySystemNames.OBTAIN_MEDICAL_RECORDS);
  readonly releaseMedicalRecords: string = this.survey(SurveySystemNames.RELEASE_MEDICAL_RECORDS);
  readonly medicalRecordsFamilyAuthorization: string = this.survey(
    SurveySystemNames.MEDICAL_RECORDS_FAMILY_AUTHORIZATION,
  );

  readonly lwaVerifyIdentity: string = '/login-with-amazon/verify-identity';
  readonly lwaLinkAccount: string = '/login-with-amazon/link-account';
  readonly lwaDataSharing: string = '/login-with-amazon/data-sharing';
  readonly apeeSignUp: string = '/amazon-pharmacy/sign-up';

  readonly mentalHealthTopics: string = '/appointments/mental-health';
  readonly travelSurvey: string = this.survey(SurveySystemNames.TRAVEL);
  readonly referrals: string = '/registration/referrals';
  readonly processAfterpay: string = `${window.location.origin}/register`;
  readonly myProvider: string = '/account/my-provider';
  readonly selectProvider: string = '/providers/select-provider';
  readonly providerHub: string = '/providers/provider-hub';
  readonly onboarding: string = '/onboarding';
  readonly appStoreRedirect: string = '/app-store-redirect';
  readonly appointmentsPage: string = '/appointments/list';
  readonly appointmentReason: string = '/appointments/reason';
  readonly appointmentReview: string = '/appointments/review';
  readonly appointmentRescheduleRemote: string = '/appointments/switch-to-remote/reschedule';
  readonly manageInsurance: string = '/billing/manage-insurance';
  readonly tasks: string = '/tasks';
  readonly messages: string = '/messages';
  readonly help: string = '/help';
  readonly trialVideoVisitConfirmation = '/membership/gcn/confirmation';
  readonly getCare: string = '/get-care';
  readonly appointmentsGetCare: string = 'appointments/get-care';
  readonly guest: string = '/guest';
  readonly programUnavailable: string = '/appointments/unavailable';
  readonly prescriptionRenewal: string = '/prescription/renew';
  readonly accountSecurity: string = '/account/security';
  readonly enableMfa: string = `${this.accountSecurity}/mfa/enable`;
  readonly cancelMembership: string = '/membership/cancel';
  readonly termsOfService: string = '/terms-of-service';

  readonly limitedAccessAmazonRegistration: string = `${this.registrationRoot}/limited_access/login_with_amazon/continue`;

  // External links
  readonly afterpay: string = 'https://portal.afterpay.com/us/login-email';
  readonly COVID_RESOURCES: string = 'https://onemedical.com/coronavirus';
  readonly SERVICES: string = 'https://www.onemedical.com/services';
  readonly INSURANCE_CHECKER: string =
    'https://www.onemedical.com/insurance/insurance-finder/?location=all-locations&carrier=all-carriers';

  readonly OFFICE_LOCATIONS = 'https://www.onemedical.com/locations/';

  survey(surveySystemName: string): string {
    return `/survey/${surveySystemName}`;
  }

  appointmentConfirmation(id: string): string {
    return `/appointments/${id}/confirmation`;
  }

  employeeIdRegistration(b2bCompanyId: number): string {
    return `${this.registrationEnterprise}/employee_id?b2b_company_id=${b2bCompanyId}`;
  }

  loginWithQueryParams(queryParams?: Record<string, string>) {
    return appendQueryParamsToPath(this.login, queryParams);
  }

  onboardingWithQueryParams(queryParams?: Record<string, string>) {
    return appendQueryParamsToPath(this.onboarding, queryParams);
  }

  registrationPediatricWithQueryParams(queryParams?: Record<string, string>) {
    return appendQueryParamsToPath(this.registrationPediatric, queryParams);
  }

  registrationEnterpriseWithQueryParams(queryParams?: Record<string, string>) {
    return appendQueryParamsToPath(this.registrationEnterprise, queryParams);
  }
}
